/* eslint-disable */
const $$ = function(id) { return (typeof (id) === 'object') ? id : document.getElementById(id); };
const ErrorMsg = 'CA类型不在支持范围内';
const SignSn = '';
const EncSn = '';
let _Break = 'true';
function NoThisCa() { alert(ErrorMsg); throw (new Error(-1, ErrorMsg)); }
function ThrowEx(Msg) { if (_Break) { alert(Msg); throw (new Error(-1, Msg)); } else {} }
function EpCaObj(NeedInitializtion, NeedBreak) { if (NeedBreak != undefined) { _Break = NeedBreak; } if (NeedInitializtion) { if (!this.InitClientCert()) { ThrowEx('CA证书读取异常,请先插好CA锁!'); } } }
EpCaObj.prototype.CAType = '127';

let SignCer = null; let EncCer = null; const Encrycrtx = '';
function decode64(input) {
  const keyStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/='; let output = ''; let chr1,
    chr2,
    chr3 = ''; let enc1,
    enc2,
    enc3,
    enc4 = ''; let i = 0; if (input.length % 4 != 0) { return ''; } const base64test = /[^A-Za-z0-9\+\/\=]/g; if (base64test.exec(input)) { return ''; } do { enc1 = keyStr.indexOf(input.charAt(i++)); enc2 = keyStr.indexOf(input.charAt(i++)); enc3 = keyStr.indexOf(input.charAt(i++)); enc4 = keyStr.indexOf(input.charAt(i++)); chr1 = (enc1 << 2) | (enc2 >> 4); chr2 = ((enc2 & 15) << 4) | (enc3 >> 2); chr3 = ((enc3 & 3) << 6) | enc4; output = output + String.fromCharCode(chr1); if (enc3 != 64) { output += String.fromCharCode(chr2); } if (enc4 != 64) { output += String.fromCharCode(chr3); } chr1 = chr2 = chr3 = ''; enc1 = enc2 = enc3 = enc4 = ''; } while (i < input.length); return output;
}
function InitSignCer() { try { $$('CertificateCollectionX').ProviderModuleName = 'SKF_USBKeyAPI_Epoint.dll'; $$('CertificateCollectionX').CryptoInterface = 3; $$('CertificateCollectionX').CF_KeyUsage = 0x20; const rtn = $$('CertificateCollectionX').Load(); if (rtn == 1) { SignCer = $$('CertificateCollectionX').GetAt(0); } else { SignCer = $$('CertificateCollectionX').SelectCertificateDialog(); } } catch (ex) { ThrowEx(ex); } }
function InitEncCer() { try { $$('CertificateCollectionX').ProviderModuleName = 'SKF_USBKeyAPI_Epoint.dll'; $$('CertificateCollectionX').CryptoInterface = 3; $$('CertificateCollectionX').CF_KeyUsage = 0x10; const rtn = $$('CertificateCollectionX').Load(); if (rtn == 1) { EncCer = $$('CertificateCollectionX').GetAt(0); } else { EncCer = $$('CertificateCollectionX').SelectCertificateDialog(); } } catch (ex) { ThrowEx('初始化加密证书选择模式失败！'); } }
EpCaObj.prototype.InitClientCert = function() { InitSignCer(); InitEncCer(); return true; };
EpCaObj.prototype.ClientSignCertSN = function() { try { const rtn = SignCer.SerialNumber; $$('EpCa1_SN').value = rtn; return rtn; } catch (ex) { ThrowEx('读取证书序列号信息出现异常，请检查锁是否插好!'); } };
EpCaObj.prototype.ClientSignCertCN = function() { try { const rtn = SignCer.FriendlyName; $$('EpCa1_CN').value = rtn; return rtn; } catch (ex) { ThrowEx('读取使用者信息出现异常，请检查锁是否插好!'); } };
EpCaObj.prototype.SignCert = function() { try { const rtn = SignCer.Content; $$('EpCa1_PublicKey').value = rtn; return rtn; } catch (ex) { ThrowEx('读取签名证书出现异常，请检查锁是否插好!'); } };
EpCaObj.prototype.EncCert = function() { try { const rtn = EncCer.Content; $$('EpCa1_EPublicKey').value = rtn; return rtn; } catch (ex) { ThrowEx('读取加密证书信息出现异常，请检查锁是否插好!'); } };
EpCaObj.prototype.CertSubjectKeyID = function() { try { const rtn = EncCer.CertSubjectKeyID; $$('EpCa1_CK').value = rtn; return rtn; } catch (ex) { ThrowEx('读取使用者密钥标识符信息出现异常，请检查锁是否插好!'); } };
EpCaObj.prototype.CertYouXiaoQi = function() { try { const rtn = SignCer.NotAfterSystemTime; $$('EpCa1_YXQ').value = rtn; return rtn; } catch (ex) { ThrowEx('读取有效期信息出现异常，请检查锁是否插好!'); } };
EpCaObj.prototype.SignData = function(OrgData) { try { $$('EpCa1_OrgData').value = OrgData; if (SignCer == null) InitSignCer(); const rtn = SignCer.PKCS1String(OrgData); $$('EpCa1_DS').value = rtn; return rtn; } catch (ex) { ThrowEx('数据签名出现异常，请检查锁是否插好!'); } };
EpCaObj.prototype.VerifyData = function(OrgData, SingCerInfo, SinInfo) { try { if (SingCerInfo == '' || SinInfo == '') return false; if (SignCer == null) InitSignCer(); return SignCer.PKCS1Verify(SinInfo, OrgData) == 0; } catch (ex) { ThrowEx('数据验签出现异常!'); } };
EpCaObj.prototype.Bfjg = function() { if (SignCer == null) InitSignCer(); return SignCer.Issuer; };
EpCaObj.prototype.ClientOrganizationID = function() { if (SignCer == null) InitSignCer(); return SignCer.OrganizationCode; };



// 单独的按钮，对数据签名验证
export function JsSignDataOnClick() {

  let startTime,
    endTime;
  let d = new Date();
  startTime = d.getTime();

  const obj1 = new EpCaObj(true);
  document.getElementById('txtSingCerInfo').value = obj1.SignCert();
  document.getElementById('txtSignInfo').value = obj1.SignData($$('txtOrgData').value);

  d = new Date();
  endTime = d.getTime();
  alert((endTime - startTime) / 1000 + '秒');
}

// 单独的按钮，对数据签名验证
export function JsVerifyDataOnClick() {
  const obj1 = new EpCaObj(false);
  document.getElementById('txtJsVerify').value = obj1.VerifyData($$('txtOrgData').value, $$('txtSingCerInfo').value, $$('txtSignInfo').value);
}

export function ReadCert() {
  var obj = new EpCaObj(true);
  // document.getElementById("TextBox1").value = obj.ClientSignCertSN();
  // document.getElementById("TextBox2").value = obj.ClientSignCertCN();
  // document.getElementById("txtSignCert").value = obj.SignCert();
  // document.getElementById("txtEnCert").value = obj.EncCert();
  // document.getElementById("txtYXQ").value = obj.CertYouXiaoQi();
  // document.getElementById("txtCertSubjectKeyID").value = obj.CertSubjectKeyID();
  // document.getElementById("txtBFZDN").value = obj.Bfjg();
  // console.log(document.getElementById("TextBox1").value, 'TextBox1');
  // console.log(document.getElementById("TextBox2").value, 'TextBox2');
  // console.log(document.getElementById("txtSignCert").value, 'txtSignCert');
  // console.log(document.getElementById("txtEnCert").value, 'txtEnCert');
  // console.log(document.getElementById("txtYXQ").value, 'txtYXQ');
  // console.log(document.getElementById("txtCertSubjectKeyID").value, 'txtCertSubjectKeyID');
  // console.log(document.getElementById("txtBFZDN").value, 'txtBFZDN');
  // const data = 'MIIEJTCCAw2gAwIBAgIFEyhGIFgwDQYJKoZIhvcNAQEFBQAwITELMAkGA1UEBhMCQ04xEjAQBgNVBAoTCUNGQ0EgT0NBMTAeFw0yMTA0MDYwODQyMTNaFw0yNTEyMDYwODQyMTNaMIGQMQswCQYDVQQGEwJjbjESMBAGA1UEChMJQ0ZDQSBPQ0ExMQ0wCwYDVQQLEwRKU0hSMQ4wDAYDVQQLEwVVbml0czFOMEwGA1UEAwxFMDQxQE45MTMyMDkwMzE0MDIzOTIwOFlA5rGf6IuP6YeR6LS45bu66K6+6ZuG5Zui5pyJ6ZmQ5YWs5Y+4QDAwMDAwMDE0MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAxwbdSLrDoPgYcZjfTg/mCNWOA7kXs+l1HYRmFPkWOKa51R5GfNRpQPyzW/JYdahPkNi5PWURCY3HdIN547Fr1j2oMVaqnHwnJsVh6pSRKPqXK1yIkL/XDgBn3lSqWLLU+/yAnt6WaihxW79IIvriAcHz7IqhXANVrdl6/6u0HVkx+ERW0l7VBOjKju7S7TWSpVmx7eenQcOENuVWS38LCFYCk4wuNxCrjuhzsqB/KpB9rSexjHsF0CwxY3+LdJ+lRmZ1+nQJca3AGy4HGKLUDiwb+enR7Eq7DqBts0nyztmVro12b1eTJPbK/6Ceqm2w5MGbGgmmn8+opUcBnAVCpQIDAQABo4HzMIHwMB8GA1UdIwQYMBaAFNHb6YiC5d0aj0yqAIy+fPKrG/bZMEgGA1UdIARBMD8wPQYIYIEchu8qAQEwMTAvBggrBgEFBQcCARYjaHR0cDovL3d3dy5jZmNhLmNvbS5jbi91cy91cy0xNC5odG0wOAYDVR0fBDEwLzAtoCugKYYnaHR0cDovL2NybC5jZmNhLmNvbS5jbi9SU0EvY3JsMjczMDIuY3JsMAsGA1UdDwQEAwIDyDAdBgNVHQ4EFgQUQO+LpPFOlPLXIbCJmoyy22rwD7QwHQYDVR0lBBYwFAYIKwYBBQUHAwIGCCsGAQUFBwMEMA0GCSqGSIb3DQEBBQUAA4IBAQB7g0V1gzaQm1Vs+hm0Fk6gW9cUUu1nVGk7KJLfvt5VVobNBiy8eDQdldyf6TZcP8i/LMK3MMPhejA38/xix8Y6Ft7lceTs1b/sEfpkXGIvVa0qQNEm1Dj0Pe9TZjr9QTrdZ7qKUGy5ad7tu6lvJnA9zcHMBqQkiqwXVZtzdyN+CSj/x0dl4WIscRI4RCtUa879t+Xqn8GZtiwOfm5sLAjEaQK0K2NkJgiNdd8cks2loQUZ3dGuFowAaGp3noYGLCfc3dKZ4ZTI8N5/eiRzYXoTMBo5IocL3bMinKB2QdCwosg+yTrcLlrK3FElKtRBZza37L+pUeC467LmKtQAcbSE';
  const data = obj.SignCert()
  return data
}
